import React from 'react';
import { Link } from 'react-router-dom';

type NewsItemProps = {
  newsId: string;
  title: string;
  date: string;
};

const NewsItem: React.FC<NewsItemProps> = (props) => {
  const { newsId, title, date } = props;
  return (
    <Link to={`/news/${newsId}`}>
      <div className="block w-full border-b border-plain-normal text-plain-dark px-3 pt-3 pb-3">
        <p className="text-sm mb-2">{date}</p>
        <p>{title}</p>
      </div>
    </Link>
  );
};

export default NewsItem;
