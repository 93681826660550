import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { loginUser } from '../App';
import { getCard } from '../api';
import { API_RESPONSE_STATUS, EXTERNAL_URLS, isNew } from '../lib';
import { getColorObjectByColorAlias, RankColorType } from '../lib/colorsByRank';

import MainLayout from '../layouts/MainLayout';
import Container from '../layouts/Container';
import Barcode from '../components/Barcode';
import Loading from '../components/Loading';
import { ReactComponent as LogoDD } from '../assets/images/dd_logo.svg';
import { ReactComponent as LogoWelcome } from '../assets/images/welcome_logo.svg';

import styles from '../assets/styles/Membership.module.scss';
import QRCode from 'react-qr-code';
import Button from '../components/Button';
import { ModalToExternal } from '../components/Modal';

type MembershipInformationProps = {
  label: string;
  value: number;
  unit: string;
  expiredAt: string;
  className?: string;
};

type CardStateProps = {
  card_number: string;
  money: number;
  money_expiration_date: string;
  point: number;
  point_expiration_date: string;
  user_status: string;
  rank_name: string;
  rank_start: number | null;
  rank_goal: number | null;
  amount_purchased: number | null;
};

const initialCardState = {
  card_number: '000000000000000',
  money: 0,
  money_expiration_date: '2099/12/31 23:59:59',
  point: 0,
  point_expiration_date: '2099/12/31 23:59:59',
  user_status: '',
  rank_name: '',
  rank_start: 0,
  rank_goal: 30000,
  amount_purchased: 0
};

type ModalStatusType = {
  registerAccount: boolean;
};

const initialModalStatus: ModalStatusType = {
  registerAccount: false
};

const MembershipInformation: React.FC<MembershipInformationProps> = (props) => {
  const { label, value, unit, expiredAt, className } = props;

  const expiredAtStr = dayjs(expiredAt).isValid()
    ? dayjs(expiredAt).format('YYYY/MM/DD')
    : null;

  return (
    <div className={`p-2 flex flex-col flex-1 items-center ${className || ''}`}>
      <span className="text-sm mb-2 text-plain-500">{label}</span>
      <span className="text-center font-bold ">
        <span className="text-xl">{value.toLocaleString()}</span>
        <span className="text-xs ml-0.5">{unit}</span>
      </span>
      {expiredAtStr && (
        <span className="text-[10px] mt-1">
          <span>{expiredAtStr}</span>
          <span>まで有効</span>
        </span>
      )}
    </div>
  );
};

const OldMembership: React.FC<
  Partial<
    Pick<
      CardStateProps,
      | 'card_number'
      | 'money'
      | 'money_expiration_date'
      | 'point'
      | 'point_expiration_date'
    >
  >
> = ({
  card_number = '',
  money = 0,
  money_expiration_date = '',
  point = 0,
  point_expiration_date = ''
}) => {
  return (
    <>
      <div className="block text-center mb-4">
        <LogoDD className="w-2/3 mx-auto -mt-4 stroke-transparent fill-current text-white" />
      </div>
      <div className="flex flex-col gap-y-2 items-center bg-white text-black py-4 rounded-md shadow-lg shadow-black/20 min-h-[300px]">
        <div className="w-[80px] h-[80px]">
          <QRCode value={card_number} size={80} />
        </div>
        <div className="flex flex-col items-center justify-center overflow-hidden mt-2 px-2 w-full h-[90px]">
          <div className="flex items-center justify-center overflow-hidden mt-2 px-2 w-full h-[90px]">
            <Barcode
              value={card_number}
              label={`カード番号 ${card_number}`}
              height={50}
            />
          </div>
        </div>
        <div className="flex w-full h-[95px]">
          <MembershipInformation
            label="チャージ残高"
            value={money}
            unit="円"
            expiredAt={money_expiration_date.slice(0, -3)}
            className="border-r border-plain-light"
          />

          <MembershipInformation
            label="保有ポイント"
            value={point}
            unit="pt"
            expiredAt={point_expiration_date.slice(0, -3)}
          />
        </div>
      </div>
    </>
  );
};

const Membership: React.FC = () => {
  const [cardStatus, setCardStatus] =
    useState<CardStateProps>(initialCardState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useRecoilState(loginUser);
  const [percentage, setPercentage] = useState<number>(0);
  const [colorObject, setColorObject] = useState<RankColorType>(
    getColorObjectByColorAlias('スタンダード')
  );

  const [modalStatus, setModalStatus] =
    useState<ModalStatusType>(initialModalStatus);

  const handleOpenRegisterAccountModal = () =>
    setModalStatus((prev) => ({ ...prev, registerAccount: true }));
  const handleCloseRegisterAccountModal = () =>
    setModalStatus((prev) => ({ ...prev, registerAccount: false }));

  const { bg, logo, heading, subheading } = colorObject;

  const navigate = useNavigate();
  useEffect(() => {
    // TODO: fetch user information
    setIsLoading(true);
    getCard()
      .then((res) => {
        if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
          const {
            card_number,
            money,
            money_expiration_date,
            point,
            point_expiration_date,
            user_status,
            amount_purchased,
            member_rank: { name: rank_name, rank_start, rank_goal }
          } = res.data;
          // TODO: judge whether the user is membership or guest.
          setUser({
            isLogin: true,
            email: '',
            isMember: user_status === 'member'
          });
          // TODO: set Card value
          setCardStatus({
            card_number,
            money: parseInt(money),
            money_expiration_date,
            point: parseInt(point),
            point_expiration_date,
            user_status,
            amount_purchased: parseInt(amount_purchased),
            rank_name,
            rank_start: parseInt(rank_start),
            rank_goal: parseInt(rank_goal)
          });
          const progress = Math.floor(
            ((parseInt(amount_purchased) - parseInt(rank_start)) /
              (parseInt(rank_goal) - parseInt(rank_start))) *
              100
          );

          setPercentage(progress);
          setColorObject(getColorObjectByColorAlias(rank_name));

          setIsLoading(false);
        } else if (res.status === 404) {
          navigate('/error/line-revoked');
        } else {
          navigate('/');
        }
      })
      .catch((err) => {
        console.log(err);
        navigate('/');
      });
  }, []);
  return (
    <MainLayout footer={true}>
      {isLoading ? (
        <Container isVerticalCenter={true} className="px-[10px]">
          <Loading />
        </Container>
      ) : (
        <Container isVerticalCenter={false} className="px-[10px]">
          <div
            className={
              isNew
                ? `flex flex-col bg-standard px-3 py-6 mb-10 rounded-md shadow-lg shadow-black/20 h-[200px] w-full ${bg}`
                : 'flex flex-col bg-plain-normal text-white px-3 py-6 mb-10 rounded-md shadow-lg shadow-black/20 h-[200px] w-full'
            }
          >
            {isNew ? (
              <>
                <div className="block text-center mb-4 px-4">
                  <LogoDD
                    className={`w-2/3 mx-auto -mt-4 stroke-transparent fill-current ${logo}`}
                  />
                  <p className={`text-[11px] ${subheading}`}>現在のステージ</p>
                  <p className={`font-bold ${heading}`}>
                    {cardStatus.rank_name}
                  </p>
                  {!['ブラック', 'ホワイト'].includes(cardStatus.rank_name) && (
                    <>
                      <div className="w-full h-[10px] my-2 bg-plain-light rounded-full">
                        <div
                          className={styles.amountProgress}
                          style={{ width: percentage + '%' }}
                        ></div>
                      </div>

                      <p className="w-full text-right">
                        <span className={`font-bold mr-0.5 ${heading}`}>
                          {cardStatus.amount_purchased?.toLocaleString()}
                        </span>
                        <span className={`text-[10px] ml-1 ${subheading}`}>
                          / {cardStatus.rank_goal?.toLocaleString()} 円
                        </span>
                      </p>
                    </>
                  )}
                </div>
                <div className="flex flex-col gap-y-2 items-center bg-white text-black py-3 rounded-md shadow-lg shadow-black/20 min-h-[225px]">
                  <div className="flex items-center justify-center overflow-hidden mt-2 px-2 w-full h-[90px]">
                    <Barcode
                      value={cardStatus.card_number}
                      label={`カード番号 ${cardStatus.card_number}`}
                      height={50}
                    />
                  </div>
                  <div className="flex w-full min-h-[65px]">
                    <MembershipInformation
                      label="チャージ残高"
                      value={cardStatus.money}
                      unit="円"
                      expiredAt={cardStatus.money_expiration_date}
                      className="border-r border-plain-light"
                    />
                    <MembershipInformation
                      label="保有ポイント"
                      value={cardStatus.point}
                      unit="pt"
                      expiredAt={cardStatus.point_expiration_date}
                    />
                  </div>
                </div>
              </>
            ) : (
              <OldMembership {...cardStatus} />
            )}

            {user.isMember ? (
              <div className="absolute bottom-[6.5rem] left-1/2 -translate-x-1/2">
                <LogoWelcome />
              </div>
            ) : (
              <div className="text-center mt-6 -mx-3 z-0 ">
                <p className="text-black mb-3 text-sm">
                  メンバー登録が済んでいない方
                </p>

                <Button
                  as="external-link-with-modal"
                  variant="primary"
                  value="メンバー登録する"
                  onClick={handleOpenRegisterAccountModal}
                />

                <ModalToExternal
                  isOpen={modalStatus.registerAccount}
                  handleClose={handleCloseRegisterAccountModal}
                  href={EXTERNAL_URLS.registerAccount}
                />
              </div>
            )}
          </div>
        </Container>
      )}
    </MainLayout>
  );
};

export default Membership;
