import React, { useState } from 'react';
import Button from '../components/Button';
import Dialog from '../components/Dialog';
import Header from '../components/Header';
import MainLayout from '../layouts/MainLayout';
import { ModalToExternal } from '../components/Modal';
import { EXTERNAL_URLS } from '../lib';

const ErrorRevokedLineConnection: React.FC = () => {
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const handleCloseConnectLineModal = () => setModalStatus(false);
  const handleOpenConnectLineModal = () => setModalStatus(true);

  return (
    <>
      <MainLayout>
        <Header title="エラー" />
        <Dialog
          subject="LINE連携が解除されています"
          description="ECサイトのマイページからLINE連携を行ってください"
          buttons={
            <Button
              as="external-link-with-modal"
              onClick={handleOpenConnectLineModal}
              variant="secondary"
              className="mt-10 font-bold"
              value="マイページへ移動する"
            />
          }
        />
      </MainLayout>
      <ModalToExternal
        isOpen={modalStatus}
        handleClose={handleCloseConnectLineModal}
        href={EXTERNAL_URLS.mypage}
      />
    </>
  );
};

export default ErrorRevokedLineConnection;
