import React, { useEffect, useState } from 'react';
import { getCard } from '../api';
import Header from '../components/Header';
import Loading from '../components/Loading';
import Container from '../layouts/Container';
import MainLayout from '../layouts/MainLayout';
import { API_RESPONSE_STATUS, splitFourNumbers } from '../lib';

type CardInformationType = {
  card_number: string;
  card_pin: string;
};

type CardInformationFieldType = {
  label: string;
  value?: string;
};

const CardInformationField: React.FC<CardInformationFieldType> = ({
  label,
  value = ''
}) => {
  return (
    <div className="mb-8 text-sm text-plain-700">
      <h1 className="mb-2 font-bold text-plain-dark">{label}：</h1>
      <p className="ml-4 font-bold text-xl">{value}</p>
    </div>
  );
};

const CardInformation: React.FC = () => {
  const [cardInformation, setCardInformation] =
    useState<CardInformationType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getCard().then((res) => {
      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
        const { card_number, card_pin } = res.data;
        setCardInformation({
          card_number,
          card_pin
        });
      }
      setIsLoading(false);
    });
  }, []);
  return (
    <MainLayout footer>
      <Header title="カード情報" />
      {isLoading ? (
        <Container isVerticalCenter={true} className="px-[10px]">
          <Loading />
        </Container>
      ) : (
        <Container className="px-6 pb-24">
          <CardInformationField
            label="カード番号"
            value={
              cardInformation?.card_number
                ? splitFourNumbers(cardInformation?.card_number, '-')
                : ''
            }
          />
          <CardInformationField
            label="PIN番号"
            value={cardInformation?.card_pin}
          />
        </Container>
      )}
    </MainLayout>
  );
};

export default CardInformation;
