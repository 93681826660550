import { typedKeys } from './typedKeys';

const COLOR_BY_RANK = {
  スタンダード: {
    bg: 'bg-rank-standard',
    logo: 'text-plain-normal',
    heading: 'text-plain-darker',
    subheading: 'text-plain-normal'
  },
  ブロンズ: {
    bg: 'bg-rank-bronze',
    logo: 'text-white',
    heading: 'text-white',
    subheading: 'text-plain-light'
  },
  シルバー: {
    bg: 'bg-rank-silver',
    logo: 'text-white',
    heading: 'text-white',
    subheading: 'text-plain-light'
  },
  ゴールド: {
    bg: 'bg-rank-gold',
    logo: 'text-white',
    heading: 'text-plain-darker',
    subheading: 'text-plain-normal'
  },
  ブラック: {
    bg: 'bg-rank-black',
    logo: 'text-white',
    heading: 'text-white',
    subheading: 'text-plain-light'
  },
  ホワイト: {
    bg: 'bg-rank-black',
    logo: 'text-white',
    heading: 'text-white',
    subheading: 'text-plain-light'
  }
};

export type RankAliasType = keyof typeof COLOR_BY_RANK;
export type RankColorType = (typeof COLOR_BY_RANK)[RankAliasType];

const rankAlias = typedKeys(COLOR_BY_RANK);

const isAvailableColorAlias = (alias: string): alias is RankAliasType =>
  (rankAlias as string[]).includes(alias);

export const getColorObjectByColorAlias = (alias: string) => {
  return isAvailableColorAlias(alias)
    ? COLOR_BY_RANK[alias]
    : COLOR_BY_RANK['スタンダード'];
};
