import dayjs from 'dayjs';

// TODO: 新規会員証で出るかstgでテストし、後で11/1 0:00に戻す
export const isNew = dayjs().isAfter(
  '2022-11-01T00:00:00+09:00',
  'millisecond'
);

export const splitFourNumbers = (input: string, delimiter: string): string => {
  return input.replace(/(\d)(?=(\d\d\d\d)+(?!\d))/g, `$1${delimiter}`);
};

const REACT_APP_DANDD_BASE_URL = process.env.REACT_APP_DANDD_BASE_URL as string;

export const EXTERNAL_URLS = {
  forgotPassword: `${REACT_APP_DANDD_BASE_URL}/shop/customer/askpass.aspx?utm_source=line&utm_medium=social&utm_campaign=miniapp`,
  registerAccount: `${REACT_APP_DANDD_BASE_URL}/shop/customer/menu.aspx?utm_source=line&utm_medium=social&utm_campaign=miniapp`,
  generateMembercard: `${REACT_APP_DANDD_BASE_URL}/shop/customer/menu.aspx?utm_source=line&utm_medium=social&utm_campaign=miniapp`,
  downloadApp: `${REACT_APP_DANDD_BASE_URL}/ddclub/clubpass?utm_source=line&utm_medium=social&utm_campaign=miniapp`,
  confirmMemberDetail: `${REACT_APP_DANDD_BASE_URL}/shop/customer/customer.aspx?utm_source=line&utm_medium=social&utm_campaign=miniapp`,
  mypage: `${REACT_APP_DANDD_BASE_URL}/shop/customer/menu.aspx?utm_source=line&utm_medium=social&utm_campaign=miniapp`,
  mergeMemberCard: `${REACT_APP_DANDD_BASE_URL}/shop/customer/mergemembercard.aspx?utm_source=line&utm_medium=social&utm_campaign=miniapp`
};

export const LIFF_ID = process.env.REACT_APP_LIFF_ID as string;

export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const API_RESPONSE_STATUS = {
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
};

export const GA_TRACKING_ID = process.env.REACT_APP_GTM_ID;

// for test
export const TEST_DATA = [
  {
    data: {
      card_number: '1234567890123456',
      money: '12345',
      money_expiration_date: '2022/09/14 23:59:59',
      point: '8888',
      point_expiration_date: '2022/09/14 23:59:59',
      user_status: 'guest',
      amount_purchased: '2222',
      member_rank: {
        name: 'スタンダード',
        rank_start: '0',
        rank_goal: '30000'
      }
    }
  },
  {
    data: {
      card_number: '1234567890123456',
      money: '12345',
      money_expiration_date: '2022/09/14 23:59:59',
      point: '8888',
      point_expiration_date: '2022/09/14 23:59:59',
      user_status: 'member',
      amount_purchased: '45000',
      member_rank: {
        name: 'ブロンズ',
        rank_start: '30000',
        rank_goal: '50000'
      }
    }
  },
  {
    data: {
      card_number: '1234567890123456',
      money: '12345',
      money_expiration_date: '2022/09/14 23:59:59',
      point: '8888',
      point_expiration_date: '2022/09/14 23:59:59',
      user_status: 'member',
      amount_purchased: '67800',
      member_rank: {
        name: 'シルバー',
        rank_start: '50000',
        rank_goal: '100000'
      }
    }
  },
  {
    data: {
      card_number: '1234567890123456',
      money: '12345',
      money_expiration_date: '2022/09/14 23:59:59',
      point: '8888',
      point_expiration_date: '2022/09/14 23:59:59',
      user_status: 'member',
      amount_purchased: '250000',
      member_rank: {
        name: 'ゴールド',
        rank_start: '100000',
        rank_goal: '1000000'
      }
    }
  },
  {
    data: {
      card_number: '1234567890123456',
      money: '12345',
      money_expiration_date: '2022/09/14 23:59:59',
      point: '8888',
      point_expiration_date: '2022/09/14 23:59:59',
      user_status: 'member',
      amount_purchased: '250000',
      member_rank: {
        name: 'ブラック',
        rank_start: null,
        rank_goal: null
      }
    }
  }
];
