import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ExternalLinkIcon } from '../assets/icons/icon-link.svg';

type ButtonProps = {
  as: 'internal-link' | 'external-link' | 'external-link-with-modal' | 'button';
  variant: 'primary' | 'secondary' | 'secondary-rounded';
  value: string;
  className?: string | undefined;
  href?: string;
  onClick?: VoidFunction;
  state?: object | null;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = (props) => {
  const { as, href, className, value, state, variant, onClick, disabled } =
    props;

  let defaultClassName = `flex flex-row items-center justify-center w-full p-2.5 border rounded-sm ${
    disabled ? 'opacity-50' : ''
  }`;
  variant === 'primary' &&
    (defaultClassName += ' bg-black border-black text-white font-bold');
  variant === 'secondary' &&
    (defaultClassName += ' bg-white border-plain-300 text-black');

  return (
    <>
      {(as === 'button' || as === 'external-link-with-modal') && (
        <button
          className={`${defaultClassName} ${className}`}
          disabled={disabled}
          onClick={onClick}
        >
          <span>{value}</span>
          {as === 'external-link-with-modal' && (
            <ExternalLinkIcon
              className={`ml-2 stroke-current mt-0.5 stroke-[0.05] ${
                variant === 'primary'
                  ? ' text-white fill-white'
                  : ' text-plain-normal fill-plain-normal'
              } `}
            />
          )}
        </button>
      )}
      {as === 'internal-link' && (
        <Link to={href || '#'} state={state} className="block">
          <div className={`text-center  ${defaultClassName} ${className}`}>
            <span>{value}</span>
          </div>
        </Link>
      )}
      {as === 'external-link' && (
        <a href={href || '#'} className="block">
          <div
            className={`text-center  ${defaultClassName} ${className} inline-flex`}
          >
            <span>{value}</span>
            {as === 'external-link' && (
              <ExternalLinkIcon
                className={`ml-2 stroke-current mt-0.5 stroke-[0.05] ${
                  variant === 'primary'
                    ? ' text-white fill-white'
                    : ' text-plain-normal fill-plain-normal'
                } `}
              />
            )}
          </div>
        </a>
      )}
    </>
  );
};

export default Button;
