import React from 'react';
import Container from '../layouts/Container';

interface DialogType {
  subject: string;
  description: string;
  buttons?: JSX.Element;
}

const Dialog: React.FC<DialogType> = ({ subject, description, buttons }) => {
  return (
    <Container isVerticalCenter={true} className="text-center">
      <h1 className="font-bold">{subject}</h1>
      <p className="text-xs leading-8 mt-6">{description}</p>
      {buttons}
    </Container>
  );
};
export default Dialog;
