import React from 'react';
import Button from '../components/Button';
import Dialog from '../components/Dialog';
import Header from '../components/Header';
import MainLayout from '../layouts/MainLayout';
import liff from '@line/liff';

const ErrorSessionExpired: React.FC = () => {
  return (
    <>
      <MainLayout>
        <Header title="エラー" />
        <Dialog
          subject="セッションの有効切れ"
          description="セッションの有効期限が切れています。アプリを再起動してください。"
          buttons={
            <Button
              as="button"
              onClick={() => liff.closeWindow()}
              variant="secondary"
              className="mt-10 font-bold"
              value="アプリを閉じる"
            />
          }
        />
      </MainLayout>
    </>
  );
};

export default ErrorSessionExpired;
