import React, { useEffect, useState } from 'react';
import { getAllNotifications } from '../api';
import Header from '../components/Header';
import NewsItem from '../components/NewsItem';
import Container from '../layouts/Container';
import MainLayout from '../layouts/MainLayout';
import Loading from '../components/Loading';
import { API_RESPONSE_STATUS } from '../lib';

type NotificationType = {
  id: string;
  title: string;
  content: string;
  date: string;
  category: string;
};

const NewsList: React.FC = () => {
  const [notifications, setNotifications] = useState<Array<NotificationType>>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getAllNotifications()
      .then((res) => {
        if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
          setNotifications(res.data.notifications);
          setIsLoading(false);
        } else {
          setNotifications([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <MainLayout className="pb-24" footer={true}>
      <Header title="お知らせ" />
      <Container className="flex flex-col -mt-6">
        {isLoading ? (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Loading />
          </div>
        ) : (
          <>
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <NewsItem
                  key={notification.id}
                  newsId={notification.id}
                  title={notification.title}
                  date={notification.date}
                />
              ))
            ) : (
              <p className="pt-1">新しいお知らせはありません。</p>
            )}{' '}
          </>
        )}
      </Container>
    </MainLayout>
  );
};

export default NewsList;
