import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import Button from '../components/Button';
import Header from '../components/Header';
import Container from '../layouts/Container';
import MainLayout from '../layouts/MainLayout';

import { ReactComponent as ExternalLinkIcon } from '../assets/icons/icon-link.svg';
import { useSetRecoilState } from 'recoil';
import { loginUser } from '../App';
import Dialog from '../components/Dialog';
import { ModalToExternal } from '../components/Modal';
import { API_RESPONSE_STATUS, EXTERNAL_URLS } from '../lib';
import { signinAsMember } from '../api';
import { RegisterUTMAccountType } from '../types/utmType';

type LinkCardFormState = 'isForm' | 'successful' | 'failure';

type LinkCardFormValue = {
  email: string;
  password: string;
};

type ModalStatusType = {
  forgotPassword: boolean;
  register: boolean;
  generateMembercard: boolean;
};

const initialModalStatus: ModalStatusType = {
  forgotPassword: false,
  register: false,
  generateMembercard: false
};

const initialUTMParams = {
  utm_source: '',
  utm_campaign: '',
  utm_content: '',
  utm_medium: ''
};

const Signin: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pageSrc = searchParams.get('src') || 'welcome';

  const location = useLocation();
  const utm_data =
    (location.state as RegisterUTMAccountType) ?? initialUTMParams;

  const setLoginUser = useSetRecoilState(loginUser);
  const [formState, setFormState] = useState<LinkCardFormState>('isForm');
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { register, handleSubmit } = useForm<LinkCardFormValue>();

  const [modalStatus, setModalStatus] =
    useState<ModalStatusType>(initialModalStatus);

  const handleOpenForgotPasswordModal = () =>
    setModalStatus((prev) => ({ ...prev, forgotPassword: true }));
  const handleCloseForgotPasswordModal = () =>
    setModalStatus((prev) => ({ ...prev, forgotPassword: false }));

  const handleOpenRegisterModal = () =>
    setModalStatus((prev) => ({ ...prev, register: true }));
  const handleCloseRegisterModal = () =>
    setModalStatus((prev) => ({ ...prev, register: false }));

  const handleOpenGenerateMembercardModal = () =>
    setModalStatus((prev) => ({ ...prev, generateMembercard: true }));
  const handleCloseGenerateMembercardModal = () =>
    setModalStatus((prev) => ({ ...prev, generateMembercard: false }));

  const onSubmit: SubmitHandler<LinkCardFormValue> = (data) => {
    setIsError(false);
    setErrorMessage('');

    // TODO: Validate the user based on email and password.
    if (data.email && data.password) {
      setIsLoading(true);
      signinAsMember(data.email, data.password, utm_data)
        .then((res) => {
          if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
            setLoginUser({
              isLogin: true,
              email: data.email,
              isMember: true
            });
            navigate('/membership');
          } else if (res.status === 404) {
            // 404 Not Found Card
            setIsLoading(false);
            setFormState('failure');
          } else if (res.data.errors.includes('別のLINEアカウントに連携済み')) {
            // FIXME: 407 error Already Existed
            setIsError(true);
            setErrorMessage('すでに別のLINEアカウントに連携済みです');
            setIsLoading(false);
          } else {
            // 403 Authentication error / any other errors
            setIsError(true);
            setErrorMessage('メールアドレスまたはパスワードが間違っています');
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsError(true);
          setIsLoading(false);
          setErrorMessage('メールアドレスまたはパスワードが間違っています');
        });
    } else {
      setIsError(true);
      setErrorMessage('メールアドレスまたはパスワードが空です');
    }
  };

  const headerTitleByPageSrc: { [index: string]: string } = {
    welcome: 'ログイン',
    membership: '会員ID連携'
  };

  const noteTitleByPageSrc: { [index: string]: string } = {
    welcome: 'カード (会員証) をお持ちでメンバー登録がまだの方',
    membership: 'メンバー登録がまだの方'
  };

  const noteDescriptionByPageSrc: { [index: string]: string } = {
    welcome:
      'メンバー登録をすると、オトクな情報をいち早く受け取れます。どうぞこの機会にご登録ください。',
    membership:
      'メンバー登録画面の「LINEでログイン」から登録を済ませた後、本ページよりログインしてください。'
  };

  const LoginForm: React.FC = () => (
    <>
      <Container>
        <div className="flex flex-col gap-y-4">
          {isError && (
            <div className="-mt-4 mb-2 text-center text-danger-normal text-sm">
              {errorMessage}
            </div>
          )}
          <div>
            <label className="block text-plain-dark text-sm font-bold mb-2">
              メールアドレス（ID）
            </label>
            <input
              type="email"
              className={`block w-full py-2 rounded-md ${
                isError ? 'border-danger-normal' : 'border-plain-dark'
              }`}
              placeholder="例：sample@sample.jp"
              {...register('email')}
            />
          </div>
          <div>
            <label className="block text-plain-dark text-sm font-bold mb-2">
              パスワード
            </label>
            <input
              type="password"
              className={`block w-full py-2 rounded-md ${
                isError ? 'border-danger-normal' : 'border-plain-dark'
              }`}
              {...register('password')}
            />
          </div>

          <Button
            as="button"
            variant="primary"
            className="mt-4"
            value={isLoading ? 'ログイン中…' : 'ログインする'}
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          />
          <div className="text-right">
            <Link
              to="#"
              className="text-xs inline-flex"
              onClick={handleOpenForgotPasswordModal}
            >
              <span className="underline mr-1 text-plain-dark">
                メールアドレス、パスワードをお忘れの方はこちら
              </span>
              <ExternalLinkIcon className="stroke-current stroke-[0.05] text-plain-normal fill-plain-normal" />
            </Link>
          </div>
        </div>
      </Container>

      <div className="mt-auto bg-ivory px-4 pt-6 pb-10">
        <h2 className="font-bold mb-2 text-sm text-plain-dark">
          {noteTitleByPageSrc[pageSrc] ?? ''}
        </h2>
        <p className="mb-4 text-xs text-plain-darkest">
          {noteDescriptionByPageSrc[pageSrc] ?? ''}
        </p>
        <Button
          as="external-link-with-modal"
          variant="secondary"
          value="メンバー登録をする"
          onClick={handleOpenRegisterModal}
        />
      </div>

      <ModalToExternal
        isOpen={modalStatus.forgotPassword}
        handleClose={handleCloseForgotPasswordModal}
        href={EXTERNAL_URLS.forgotPassword}
      />
      <ModalToExternal
        isOpen={modalStatus.register}
        handleClose={handleCloseRegisterModal}
        href={EXTERNAL_URLS.registerAccount}
      />
    </>
  );

  const LoginSuccessful: React.FC = () => (
    <Dialog
      subject="登録が完了しました"
      description="次回から会員証ページが表示されます。レジにて画面をお見せください。お手持ちのカードは引き続きご利用いただけます。"
      buttons={
        <Button
          as="internal-link"
          variant="primary"
          className="mt-12"
          href="/membership"
          value="会員証を表示する"
        />
      }
    />
  );

  const LoginFailure: React.FC = () => (
    <>
      <Dialog
        subject="メンバーズカードが登録されていません"
        description="LINEで会員証を表示するには下のボタンからメンバーズカードを発行してください。"
        buttons={
          <Button
            as="external-link-with-modal"
            onClick={handleOpenGenerateMembercardModal}
            variant="secondary"
            className="mt-10 font-bold"
            value="メンバーズカードを発行する"
          />
        }
      />
      <ModalToExternal
        isOpen={modalStatus.generateMembercard}
        handleClose={handleCloseGenerateMembercardModal}
        href={EXTERNAL_URLS.generateMembercard}
      />
    </>
  );

  return (
    <MainLayout>
      <Header title={headerTitleByPageSrc[pageSrc] ?? ''} />
      {formState === 'isForm' && <LoginForm />}
      {formState === 'successful' && <LoginSuccessful />}
      {formState === 'failure' && <LoginFailure />}
    </MainLayout>
  );
};

export default Signin;
