import React from 'react';
import liff from '@line/liff';

import Button from '../components/Button';
import Dialog from '../components/Dialog';
import Header from '../components/Header';
import MainLayout from '../layouts/MainLayout';

const ErrorTimeout: React.FC = () => (
  <MainLayout>
    <Header title="エラー" />

    <Dialog
      subject="タイムアウトエラー"
      description="タイムアウトエラーが発生しました。時間を置いて再起動してください。"
      buttons={
        <Button
          as="button"
          onClick={() => liff.closeWindow()}
          variant="secondary"
          className="mt-10 font-bold"
          value="アプリを閉じる"
        />
      }
    />
  </MainLayout>
);

export default ErrorTimeout;
