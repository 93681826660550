import React from 'react';

type HeaderProps = {
  title: string;
};

const Header: React.FC<HeaderProps> = (props) => {
  const { title } = props;
  return (
    <div className="block w-full border-t-4 border-black px-4">
      <div className="border-b py-2">{title}</div>
    </div>
  );
};

export default Header;
