import liff from '@line/liff';

/**
 * @param url - url string
 * @param external - 外部ブラウザーで開く
 * @returns void
 */
export const openWindow = (url: string, external?: boolean) => {
  if (url) {
    liff.openWindow({
      url: url,
      external: external ?? false
    });
  }
};
