import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getNotificationById } from '../api';
import Header from '../components/Header';
import Loading from '../components/Loading';
import Container from '../layouts/Container';
import MainLayout from '../layouts/MainLayout';
import { API_RESPONSE_STATUS } from '../lib';

type NotificationType = {
  id: string;
  title: string;
  content: string;
  date: string;
  category: string;
};

const initialNotificationValue = {
  id: '',
  title: '',
  content: '',
  date: '',
  category: ''
};

const NewsDetail: React.FC = () => {
  const [notificaiton, setNotification] = useState<NotificationType>(
    initialNotificationValue
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getNotificationById(id ?? '')
      .then((res) => {
        if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
          setNotification(res.data);
          setIsLoading(false);
        } else {
          setNotification(initialNotificationValue);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <MainLayout>
      <Header title="お知らせ" />
      <Container className="px-6 pb-24 text-plain-darker">
        {isLoading ? (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Loading />
          </div>
        ) : (
          <>
            <h1 className="text-center font-bold text-xl mb-8">
              {notificaiton.title}
            </h1>
            <div className="leading-loose mb-8 flex flex-col gap-y-8">
              {notificaiton.content.split('\n').map((sentence, index) => (
                <p key={index}>{sentence}</p>
              ))}
            </div>
            <p className="text-right">{notificaiton.date}</p>
          </>
        )}
      </Container>
    </MainLayout>
  );
};

export default NewsDetail;
