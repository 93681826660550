import React, { useEffect, useState } from 'react';
import logoBlack from '../assets/images/logo_black.svg';
import MainLayout from '../layouts/MainLayout';
import Container from '../layouts/Container';
import { useSetRecoilState } from 'recoil';
import { loginUser } from '../App';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCard, signupAsGuest } from '../api';
import { API_RESPONSE_STATUS } from '../lib';
import Loading from '../components/Loading';
import Button from '../components/Button';

const Welcome: React.FC = () => {
  const [searchParams] = useSearchParams();
  const setLoginUser = useSetRecoilState(loginUser);
  const [isGetCardLoading, setIsGetCardLoading] = useState<boolean>(false);
  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const utmSource = searchParams.get('utm_source') || '';
  const utmCampaign = searchParams.get('utm_campaign') || '';
  const utmContent = searchParams.get('utm_content') || '';
  const utmMedium = searchParams.get('utm_medium') || '';

  useEffect(() => {
    setIsGetCardLoading(true);
    getCard().then((res) => {
      if (res?.status === API_RESPONSE_STATUS.SUCCEEDED) {
        const { user_status } = res.data;
        // TODO: judge whether the user is membership or guest.

        setLoginUser({
          isLogin: true,
          email: '',
          isMember: user_status === 'member'
        });
        setIsGetCardLoading(false);
        navigate('/membership');
      } else if (res.status === 404) {
        navigate('/error/line-revoked');
      }
      setIsGetCardLoading(false);
    });
  }, []);

  const handleCreateMembership = () => {
    // Create Membership Process and treat as guest.
    setIsCreateLoading(true);
    signupAsGuest({
      utm_source: utmSource,
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_medium: utmMedium
    })
      .then((res) => {
        if (res?.status === API_RESPONSE_STATUS.SUCCEEDED) {
          setLoginUser({
            isLogin: true,
            email: '',
            isMember: false
          });
          // TODO: Add utm params
          navigate('/membership');
        } else {
          setIsCreateLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsCreateLoading(false);
      });
  };

  return (
    <MainLayout>
      {isGetCardLoading ? (
        <div className="h-screen w-screen flex items-center justify-center bg-white z-20">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Loading />
          </div>
        </div>
      ) : (
        <Container isVerticalCenter={true}>
          <img className="mb-[35%]" src={logoBlack} alt="DEAN &amp; DELUCA" />
          <div className="flex flex-col gap-y-6">
            <Button
              as="button"
              variant="primary"
              href="#"
              value={
                isCreateLoading
                  ? 'LINE会員証 新規発行中…'
                  : '新しくLINE会員証を発行する'
              }
              onClick={handleCreateMembership}
              disabled={isCreateLoading}
            />
            <Button
              as="internal-link"
              variant="secondary"
              href="/signin?src=welcome"
              state={{
                utm_source: utmSource,
                utm_campaign: utmCampaign,
                utm_content: utmContent,
                utm_medium: utmMedium
              }}
              value="すでにメンバーの方はこちら"
              disabled={isCreateLoading}
            />
          </div>
        </Container>
      )}
    </MainLayout>
  );
};

export default Welcome;
