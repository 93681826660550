import React from 'react';
import ReactJSBarcode from 'react-jsbarcode';

interface BarcodeType {
  value: string;
  label?: string;
  height?: number;
}

const Barcode: React.FC<BarcodeType> = ({ value, label, height }) => {
  return (
    <ReactJSBarcode
      value={`A${value}C`}
      options={{
        height: height ?? 50,
        width: 1.5,
        text: label,
        fontSize: 12,
        font: 'Noto Sans JP, monospace',
        format: 'codabar'
      }}
    />
  );
};
export default Barcode;
