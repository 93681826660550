import React, { ReactNode } from 'react';
import Footer from '../components/Footer';

const MainLayout: React.FC<{
  footer?: boolean;
  className?: string;
  children: ReactNode;
}> = ({ footer, className, children }) => {
  let classList = 'w-full h-screen flex flex-col overflow-y-scroll';
  className && (classList += ` ${className}`);
  footer && (classList += ' with-footer pb-[70px]');

  return (
    <>
      <div className={classList}>{children}</div>
      {footer && <Footer />}
    </>
  );
};
export default MainLayout;
