import liff from '@line/liff';
import axios, { AxiosRequestHeaders } from 'axios';
import { BASE_URL } from '../lib';
import { RegisterUTMAccountType } from '../types/utmType';

interface RequestType {
  url: string;
  method: 'GET' | 'POST';
  hasAccessToken?: boolean;
  data?: object;
  params?: object;
  errLabel: string;
}

const sendRequest = ({
  url,
  method,
  hasAccessToken,
  data,
  params,
  errLabel
}: RequestType) => {
  const idToken = liff.getIDToken() as string;
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json; charset=UTF-8',
    'Token-Id': idToken
  };

  if (hasAccessToken) {
    const accessToken = liff.getAccessToken() as string;
    headers['Access-Token'] = accessToken;
  }

  return axios({
    url: `${BASE_URL}${url}`,
    method: method,
    headers: headers,
    data: data,
    params: params
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(`${errLabel}: `, err.response);

      // Check whether cx should be moved to /error page on api error 408 (request timeout) / 500 (server error).
      if ([408].includes(err.response.status)) {
        window.location.replace(process.env.PUBLIC_URL + '/error/timeout');
      }
      if ([500].includes(err.response.status)) {
        window.location.replace(process.env.PUBLIC_URL + '/error');
      }
      if ([503].includes(err.response.status)) {
        window.location.replace(
          process.env.PUBLIC_URL + '/error/service-unavailable'
        );
      }

      if (err.response) {
        const error = err.response.data.errors;
        if (error.includes('IdToken expired.')) {
          localStorage.clear();
          window.location.replace(
            process.env.PUBLIC_URL + '/error/session-expired'
          );
        }
      }

      return err.response;
    });
};

export const getCard = () => {
  return sendRequest({
    url: '/users/current/cards',
    method: 'GET',
    errLabel: 'ErrorGetCard'
  });
};

export const signupAsGuest = (utm_data?: RegisterUTMAccountType) => {
  return sendRequest({
    url: '/guests',
    method: 'POST',
    hasAccessToken: true,
    data: utm_data,
    errLabel: 'ErrorSignupAsGuest'
  });
};

export const signinAsMember = (
  email: string,
  password: string,
  utm_data?: RegisterUTMAccountType | null
) => {
  return sendRequest({
    url: '/members',
    method: 'POST',
    hasAccessToken: true,
    data: {
      password,
      login_id: email,
      ...utm_data
    },
    errLabel: 'ErrorSigninAsMember'
  });
};

export const getAllNotifications = () => {
  return sendRequest({
    url: '/notifications',
    method: 'GET',
    errLabel: 'ErrorGetNotifications'
  });
};

export const getNotificationById = (id: string) => {
  return sendRequest({
    url: `/notifications/${id}`,
    method: 'GET',
    errLabel: `ErrorGetNotification id:${id}`
  });
};
