import React, { useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { ReactComponent as MemberCardIcon } from '../assets/images/footerMembersCard.svg';
import { ReactComponent as NewsIcon } from '../assets/images/footerNews.svg';
import { ReactComponent as ExternalLinkIcon } from '../assets/icons/icon-link.svg';
import style from '../assets/styles/Footer.module.scss';
import { useRecoilState } from 'recoil';
import { loginUser } from '../App';
import { ModalToExternal } from './Modal';
import { EXTERNAL_URLS, isNew } from '../lib';

type ModalStatusType = {
  downloadApp: boolean;
  confirmMemberDetail: boolean;
  registerAccount: boolean;
  mergeMemberCard: boolean;
};

const initialModalStatus: ModalStatusType = {
  downloadApp: false,
  confirmMemberDetail: false,
  registerAccount: false,
  mergeMemberCard: false
};

const Footer: React.FC = () => {
  const [existsNewNotifications] = useState<boolean>(false);

  const [user] = useRecoilState(loginUser);

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const { pathname } = useLocation();
  let isMembershipActive = false;
  let isNewsActive = false;

  if (matchPath('/membership/*', pathname)) {
    isMembershipActive = true;
  }
  if (matchPath('/news/*', pathname)) {
    isNewsActive = true;
  }

  const toggleShowingMenu = () => {
    setShowMenu((state) => !state);
  };

  const [modalStatus, setModalStatus] =
    useState<ModalStatusType>(initialModalStatus);

  const handleOpenDownloadAppModal = () =>
    setModalStatus((prev) => ({ ...prev, downloadApp: true }));
  const handleCloseDownloadAppModal = () =>
    setModalStatus((prev) => ({ ...prev, downloadApp: false }));

  const handleOpenConfirmMemberDetailModal = () =>
    setModalStatus((prev) => ({ ...prev, confirmMemberDetail: true }));
  const handleCloseConfirmMemberDetailModal = () =>
    setModalStatus((prev) => ({ ...prev, confirmMemberDetail: false }));

  const handleOpenRegisterAccountModal = () =>
    setModalStatus((prev) => ({ ...prev, registerAccount: true }));
  const handleCloseRegisterAccountModal = () =>
    setModalStatus((prev) => ({ ...prev, registerAccount: false }));

  const handleOpenMergeMemberCardModal = () =>
    setModalStatus((prev) => ({ ...prev, mergeMemberCard: true }));
  const handleCloseMergeMemberCardModal = () =>
    setModalStatus((prev) => ({ ...prev, mergeMemberCard: false }));

  return (
    <>
      <div
        className={`absolute top-0 left-0 w-full h-full bg-black/50 ${
          !showMenu && 'hidden'
        }`}
      ></div>
      <div className={style.nav}>
        <Link
          to="/membership"
          className={`${style.navItem} ${isMembershipActive && style.active}`}
        >
          <figure>
            <MemberCardIcon />
          </figure>

          <span>会員証</span>
        </Link>
        <Link
          to="/news"
          className={`${style.navItem} ${isNewsActive && style.active} ${
            existsNewNotifications && style.badge
          }`}
        >
          <figure>
            <NewsIcon />
          </figure>

          <span>お知らせ</span>
        </Link>
        <div
          className={`${style.navItem} ${showMenu && style.active}`}
          onClick={toggleShowingMenu}
        >
          <div className={style.humberger}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <span>メニュー</span>
        </div>
      </div>

      <div className={`${style.menu} ${!showMenu && 'hidden'}`}>
        {user.isMember ? (
          <div
            className="block border-b border-b-plain-light p-3"
            onClick={handleOpenConfirmMemberDetailModal}
          >
            <span className="text-sm text-plain-normal">
              メンバー情報を確認する
            </span>
            <ExternalLinkIcon className="inline-block ml-1 stroke-current stroke-[0.05] text-plain-normal fill-plain-normal" />
          </div>
        ) : (
          <>
            <div
              className="block border-b border-b-plain-light p-3"
              onClick={handleOpenRegisterAccountModal}
            >
              <span className="text-sm text-plain-normal">メンバー登録</span>
              <ExternalLinkIcon className="inline-block ml-1 stroke-current stroke-[0.05] text-plain-normal fill-plain-normal" />
            </div>

            <div
              className="block border-b border-b-plain-light p-3"
              onClick={handleOpenMergeMemberCardModal}
            >
              <span className="text-sm text-plain-normal">
                カードを統合する（メンバー登録済みの方）
              </span>
              <ExternalLinkIcon className="inline-block ml-1 stroke-current stroke-[0.05] text-plain-normal fill-plain-normal" />
            </div>
          </>
        )}
        {isNew && (
          <Link
            className="block border-b border-b-plain-light p-3"
            to="/membership/card-info"
          >
            <span className="text-sm text-plain-normal">
              カード情報・PIN番号照会
            </span>
          </Link>
        )}
        <div className="block p-3" onClick={handleOpenDownloadAppModal}>
          <span className="text-sm text-plain-normal">
            アプリをダウンロードする
          </span>
          <ExternalLinkIcon className="inline-block ml-1 stroke-current stroke-[0.05] text-plain-normal fill-plain-normal" />
        </div>
      </div>
      <ModalToExternal
        isOpen={modalStatus.downloadApp}
        handleClose={handleCloseDownloadAppModal}
        href={EXTERNAL_URLS.downloadApp}
      />
      <ModalToExternal
        isOpen={modalStatus.confirmMemberDetail}
        handleClose={handleCloseConfirmMemberDetailModal}
        href={EXTERNAL_URLS.confirmMemberDetail}
      />
      <ModalToExternal
        isOpen={modalStatus.registerAccount}
        handleClose={handleCloseRegisterAccountModal}
        href={EXTERNAL_URLS.registerAccount}
      />
      <ModalToExternal
        isOpen={modalStatus.mergeMemberCard}
        handleClose={handleCloseMergeMemberCardModal}
        href={EXTERNAL_URLS.mergeMemberCard}
      />
    </>
  );
};

export default Footer;
