import React, { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from './Button';
import { openWindow } from '../lib/line';

type ModalType = {
  isOpen: boolean;
  handleClose: VoidFunction;
};

const Modal: React.FC<ModalType & { children: ReactNode }> = ({
  isOpen,
  handleClose,
  children
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed w-screen h-screen bg-black bg-opacity-50"></Dialog.Overlay>
        </Transition.Child>
        <div className="min-h-screen px-4 text-center">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md px-6 py-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export const ModalToExternal: React.FC<ModalType & { href: string }> = ({
  isOpen,
  handleClose,
  href
}) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Dialog.Title
        as="h3"
        className="text-lg font-medium leading-6 text-gray-900 mb-4"
      >
        外部サイトへ移動します。よろしいですか。
      </Dialog.Title>

      <div className="mt-4 flex gap-2">
        <div className="w-full">
          <Button
            as="button"
            onClick={handleClose}
            href="#"
            variant="secondary"
            value="キャンセル"
          />
        </div>
        <div className="w-full">
          <Button
            as="external-link-with-modal"
            onClick={() => openWindow(href)}
            variant="primary"
            value="移動する"
          />
        </div>
      </div>
    </Modal>
  );
};

export default Modal;
