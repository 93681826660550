import React, { ReactNode } from 'react';

const Container: React.FC<{
  className?: string;
  isVerticalCenter?: boolean;
  children: ReactNode;
}> = ({ className, children, isVerticalCenter }) => {
  let classList = 'container px-4 flex flex-col';
  isVerticalCenter ? (classList += ' my-auto') : (classList += ' pt-6');
  className && (classList += ` ${className}`);

  return (
    <>
      <div className={classList}>{children}</div>
    </>
  );
};
export default Container;
