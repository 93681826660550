import React, { ReactNode, useLayoutEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import GoogleAnalytics from './lib/GoogleAnalytics';
import { atom, RecoilRoot } from 'recoil';
import Membership from './pages/Membership';
import NewsDetail from './pages/NewsDetail';
import NewsList from './pages/NewsList';
import Welcome from './pages/Welcome';
import CardInformation from './pages/CardInformation';
import Error from './pages/Error';
import ErrorRevokedLineConnection from './pages/ErrorRevokedLineConnection';
import ErrorSessionExpired from './pages/ErrorSessionExpired';
import ErrorServiceUnavailable from './pages/ErrorServiceUnavailable ';
import ErrorTimeout from './pages/ErrorTimeout';
import Signin from './pages/Signin';

const ScrollToTop = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return <>{children}</>;
};

export const loginUser = atom({
  key: 'loginUser',
  default: {
    isLogin: false,
    email: '',
    isMember: false
  }
});

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <HelmetProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL as string}>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Welcome />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/membership" element={<Membership />} />
              <Route
                path="/membership/card-info"
                element={<CardInformation />}
              />
              <Route path="/news" element={<NewsList />} />
              <Route path="/news/:id" element={<NewsDetail />} />
              <Route path="/error" element={<Error />} />
              <Route
                path="/error/line-revoked"
                element={<ErrorRevokedLineConnection />}
              />
              <Route
                path="/error/session-expired"
                element={<ErrorSessionExpired />}
              />
              <Route path="/error/timeout" element={<ErrorTimeout />} />
              <Route
                path="/error/service-unavailable"
                element={<ErrorServiceUnavailable />}
              />
            </Routes>
          </ScrollToTop>
          <GoogleAnalytics />
        </BrowserRouter>
      </HelmetProvider>
    </RecoilRoot>
  );
};

export default App;
