import React from 'react';
import Button from '../components/Button';
import Dialog from '../components/Dialog';
import Header from '../components/Header';
import MainLayout from '../layouts/MainLayout';

const Error: React.FC = () => {
  return (
    <MainLayout>
      <Header title="エラー" />
      <Dialog
        subject="サーバー側でエラーが発生しました"
        description="お手数ですが、「戻る」ボタンからやり直してください。"
        buttons={
          <Button
            as="internal-link"
            href="/membership"
            variant="secondary"
            className="mt-10 font-bold"
            value="戻る"
          />
        }
      />
    </MainLayout>
  );
};

export default Error;
